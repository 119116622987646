import {setTourCompletedTC} from "./redux/reducers/commonReducer";
import {NavigateFunction} from "react-router-dom";
import {Dispatch} from "redux";

import {v1} from "uuid";

export const timeZonesArray = [
    {id: 0, name: "GMT - 11:00 (Midway Islands Time)", value: -11},
    {id: 1, name: "GMT - 10:00 (Hawaii Standard Time)", value: -10},
    {id: 2, name: "GMT - 09:00 (Alaska Standard Time)", value: -9},
    {id: 3, name: "GMT - 08:00 (Pacific Standard Time)", value: -8},
    {id: 4, name: "GMT - 07:00 (Mountain Standard Time)", value: -7},
    {id: 5, name: "GMT - 06:00 (Central Standard Time)", value: -6},
    {id: 6, name: "GMT - 04:00 (Puerto Rico and US Virgin Islands Time)", value: -5},
    {id: 7, name: "GMT - 05:00 (Eastern Standard Time)", value: -4},
    {id: 8, name: "GMT - 03:00 (Argentina Standard Time)", value: -3},
    {id: 10, name: "GMT - 01:00 (Central African Time)", value: -1},
    {id: 11, name: "GMT (Greenwich Mean Time)", value: 0},
    {id: 12, name: "GMT + 01:00 (European Central Time)", value: 1},
    {id: 13, name: "GMT + 02:00 (Eastern European Time)", value: 2},
    {id: 14, name: "GMT + 03:00 (Eastern African Time)", value: 3},
    {id: 15, name: "GMT + 04:00 (Near East Time)", value: 4},
    {id: 16, name: "GMT + 05:00 (Pakistan Lahore Time)", value: 5},
    {id: 17, name: "GMT + 06:00 (Bangladesh Standard Time)", value: 6},
    {id: 18, name: "GMT + 07:00 (Vietnam Standard Time)", value: 7},
    {id: 19, name: "GMT + 08:00 (China Taiwan Time)", value: 8},
    {id: 20, name: "GMT + 09:00 (Japan Standard Time)", value: 9},
    {id: 21, name: "GMT + 10:00 (Australia Eastern Time)", value: 10},
    {id: 22, name: "GMT + 11:00 (Solomon Standard Time)", value: 11},
    {id: 23, name: "GMT + 12:00 (New Zealand Standard Time)", value: 12},
];

export const formatDate = (date: any) => {
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`
}

export const onChangeTime = (date: any) => {
    let hours =
        +date[0].getHours().toString().length == 2
            ? date[0].getHours()
            : `0${date[0].getHours()}`; // if 7 -> 07

    let minutes =
        +date[0].getMinutes().toString().length == 2
            ? date[0].getMinutes()
            : `0${date[0].getMinutes()}`; // if 0 -> 00

    date = `${hours}:${minutes}`;
    return date;
}

export const negativeId = () => {
    return `${-(Math.random() * 8).toString().split('.')[1].substring(0, 5)}`;
}

//это функция для трансформации фопросов приходящих с сервера для конкретного пульса конкретной команды в нужный формат
export type InfoForCertainPulseCertainTeamType = {
    createdAt: string,
    digitalPulseId: number,
    id: number,
    pool: Array<string> | null,
    // stats: {answers: Array<{question: string, score: number}>} | {positiveAnswers: number, negativeAnswers: number, neutralAnswers: number, average: number} |
    //     {positiveAnswers: number, negativeAnswers: number, average: number} | {answers: Array<string>}
    stats: any,
    text: string,
    typeId: number,
    updatedAt: string

}

export const transformTeamAnswers = (questions: Array<InfoForCertainPulseCertainTeamType>) => {
    return questions.map(question => {
        switch (question.typeId) {
            case 6:
                return {
                    questionType: 6,
                    question: question.text,
                    average: question.stats.average,
                    distribution: [
                        {
                            name: 'Agree',
                            data: [question.stats.positiveAnswers]
                        },
                        {
                            name: 'Neutral',
                            data: [question.stats.neutralAnswers]
                        },
                        {
                            name: 'Disagree',
                            data: [question.stats.negativeAnswers]
                        }

                    ]
                }
            case 5:
                return {
                    questionType: 5,
                    question: question.text,
                    average: question.stats.average,
                    distribution: [
                        {
                            name: 'Agree',
                            data: [question.stats.positiveAnswers]
                        },
                        {
                            name: 'Neutral',
                            data: [question.stats.neutralAnswers]
                        },
                        {
                            name: 'Disagree',
                            data: [question.stats.negativeAnswers]
                        }

                    ]
                }
            case 4:
                return {
                    questionType: 4,
                    question: question.text,
                    average: question.stats.average,
                    distribution: [
                        {
                            name: 'Yes',
                            data: [question.stats.positiveAnswers]
                        },
                        {
                            name: 'No',
                            data: [question.stats.negativeAnswers]
                        }
                    ]
                }
            case 3:
                return {
                    questionType: 3,
                    question: question.text,
                    distribution: question.stats.answers //это массив строк
                }
            case 2:
                return {
                    questionType: 2,
                    question: question.text,
                    distribution: question.stats.answers.map((question: any) => ({
                        name: question.question,
                        value: [
                            {
                                name: 'Yes',
                                data: [question.score]
                            },
                            {
                                name: '',
                                data: [2000]
                            }
                        ]
                    }))
                }
            default:
                return {}
        }
    })
}


//Для того, чтобы в сафари тур работал и скролился

export const getScrollingBehaviourForTour = (isTourCompleted: boolean) => {
    if (!isTourCompleted) {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        document.getElementsByTagName('body')[0].style.position = 'relative';
        document.documentElement.style.scrollBehavior = 'inherit';
    } else {
        document.getElementsByTagName('body')[0].style.removeProperty('overflow');
        document.getElementsByTagName('body')[0].style.removeProperty('position');
        document.documentElement.style.scrollBehavior = 'smooth';
    }
}

export const closeTour = (currentStatus: any,
                          navigate: NavigateFunction,
                          dispatch: Dispatch<any>) => {
    dispatch(setTourCompletedTC(currentStatus, navigate));
}

//for showing error-alert when smth wrong on back
export const errorAlertItem = {
    id: v1(),
    message: 'Something wrong!',
    status: 'error' as const,
    showCloseButton: false,
}



