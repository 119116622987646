import React from "react";
import style from './deleteModalWindowBody.module.scss';

import ButtonWithoutImage from "../../../../parts/buttonWithoutImage/buttonWithoutImage";

type DeleteModalWindowBodyType = {
    itemToDelete: string,
    closeModalWindow: () => void,
    hideNotification: () => void,
    actionHandler: () => void,
}
const DeleteModalWindowBody = ({itemToDelete, closeModalWindow, hideNotification, actionHandler}: DeleteModalWindowBodyType) => {

    return (
        <div className={style.mw_body}>
            <p className={style.mw_body_title}>Are you sure you want to delete {itemToDelete}?</p>
            <div className={style.mw_button}>
                <ButtonWithoutImage width={9.5} name={'Yes'}
                                    onClickHandler={actionHandler}></ButtonWithoutImage>
                <ButtonWithoutImage width={9.5} name={'No'}
                                    onClickHandler={closeModalWindow}></ButtonWithoutImage>
            </div>
        </div>
    )
}

export default DeleteModalWindowBody;