import React, {useCallback, useEffect, useState} from "react";
import style from './../inviteModalWindowBody/inviteModalWindowBody.module.scss';
import ButtonWithImage from "../../../../parts/buttonWithImage/buttonWithImage";
import save from './../../../../../assets/images/common/save.png';
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../../../redux/store/store";


import {LoaderDots} from "@thumbtack/thumbprint-react";
import {
    addDepartmentLeaderTC,
    chooseNewDepartmentLeaderTC, CompanyInfoAdminType, CompanyInfoDepartmentType, createNewAdminTC,
    getPossibleLeadersCompanyManagementAC,
    getPossibleLeadersTC, PossibleLeaderUpdatedType
} from "../../../../../redux/reducers/companyManagementReducer";
import Select, {SingleValue} from "react-select";
import {stat} from "fs";
import CustomSelect from "../../../../parts/customSelect/customSelect";
import {SubmitHandler, useForm} from "react-hook-form";
import {negativeId} from "../../../../../common";
import {v1} from "uuid";
import team from "../../../departmentManagement/departmentInformation/team/team";

type InviteModalWindowPropsType = {
    itemType: 'admin' | 'department' | 'team' | 'member' | null,
    actionType: 'add' | 'change' | 'choose' | 'delete' | null,
    chosenItemForModalWindowId: string | number | null,
    possibleLeaders: any,
    chooseNewLeader: (chosenItemForModalWindowId: string | number,
                      setError: (notification: string) => void,
                      setSuccess: (notification: string) => void,
                      chosenLeaderId: string | number | null,
                      newInvitedUserInfo: { email: string, firstName: string, lastName: string },
                      infoKeep: {keep: boolean} | null
    ) => void,
    closeModalWindow: () => void,
    setError: (err: string) => void,
    setSuccess: (notification: string) => void,
    secondaryLeaderName: string | null,
}

type InputsType = {
    firstName: string,
    lastName: string,
    email: string,
    choice: string
}

const ChooseModalWindowBody = ({
                                   itemType,
                                   actionType,
                                   chosenItemForModalWindowId,
                                   possibleLeaders,
                                   chooseNewLeader,
                                   closeModalWindow,
                                   setError,
                                   setSuccess,
                                   secondaryLeaderName,
                               }: InviteModalWindowPropsType) => {
    const dispatch = useDispatch();
    const loading = useSelector((state: RootStateType) => state.common.loading);

    //нельзя отсюда брать айди, потому что там разные типы с разных страниц. так что оставляем через пропся
    const chosenItemForModalWindow = useSelector((state: RootStateType) => state.companyManagement.chosenItemForModalWindow);

    const {register, handleSubmit, watch, formState: {errors}, reset} = useForm<InputsType>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            choice: '2',
        }
    });

    const onSubmit: SubmitHandler<InputsType> = (data) => {

        let info = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email
        }

        let infoKeep = {
            keep: data.choice === '2'
        }

        //fix 31102023
        if (itemType === 'team' && !secondaryLeaderName && chosenFlag?.value === 1) {
            //здесь шлем по новому роуту с инфо на инвайт без chosenLeader
            chosenItemForModalWindowId && chooseNewLeader(chosenItemForModalWindowId, setError, setSuccess, null, info, null);
        } else if (itemType === 'team' && secondaryLeaderName) {
            chosenItemForModalWindowId && chooseNewLeader(chosenItemForModalWindowId, setError, setSuccess, null, info, infoKeep);
        } else {
            chosenLeader && chosenItemForModalWindowId && chooseNewLeader(chosenItemForModalWindowId, setError, setSuccess, chosenLeader.id, info, infoKeep)
        }
    }

    const [chosenFlag, setChosenFlag] = useState<SingleValue<{ label: string, value: number }> | null>(null);
    const flags = [{value: 1, label: 'Add new leader'}, {value: 2, label: 'Select existing leader'}]

    const changeFlag = (flag: SingleValue<{ label: string, value: number }>) => {
        flag?.value === 2 && reset();
        flag?.value === 1 && setChosenLeader(null);
        setChosenFlag(flag)
    }

    //let secondaryLeaderName = useSelector((state: RootStateType) => state.departmentManagement.certainTeamPage.certainTeamPageInfo.secondaryLeaderName);
    const [chosenLeader, setChosenLeader] = useState<SingleValue<PossibleLeaderUpdatedType> | null>(null);
    const chooseLeader = (value: SingleValue<PossibleLeaderUpdatedType>) => {
        setChosenLeader(value);
    }

    let isDisabledButton = loading || itemType === 'team' && !secondaryLeaderName && chosenFlag?.value === 2 && !chosenLeader
        || itemType === 'department' && !chosenLeader;

    //get list of possible leaders
    useEffect(() => {
        //здесь будут разные санки в зависимости от selectedItemType !!!!
        let forRightRoute = itemType === 'team' ? 'team' as const : itemType === 'department' ? 'department' as const : null;
        forRightRoute && dispatch(getPossibleLeadersTC(forRightRoute, setError));

        return () => {
            dispatch(getPossibleLeadersCompanyManagementAC([]))
        }
    }, [])

    //this comments before fixies 31102023
    // useEffect(() => {
    //     let selectedValue = possibleLeaders.filter((l: PossibleLeaderUpdatedType) => l.label === secondaryLeaderName)[0];
    //     itemType === 'team' && secondaryLeaderName && actionType === 'choose' && setChosenLeader(selectedValue);
    // }, [secondaryLeaderName, itemType, actionType, possibleLeaders])
    ///////////////////////////////////////////////////////////////////////////////

    return (
        possibleLeaders.length ? <form id={'test'} onSubmit={handleSubmit(onSubmit)}>
            {chosenItemForModalWindow && itemType === 'team' && secondaryLeaderName && actionType === 'choose' ?
                <p className={style.title_hint}>You are changing your Secondary Team Leader for the {chosenItemForModalWindow?.name}</p> : null}
            {
                itemType === 'team' && !secondaryLeaderName
                && <div className={style.input_text_wrapper}>
                    <label>Select an option:</label>
                    <Select classNamePrefix="customSelectFlag"
                            value={chosenFlag}
                            options={flags}
                            isSearchable={false}
                            onChange={changeFlag}/>
                </div>

            }

            {/*fix there 31102023*/}
            {/*{(itemType === 'department' || itemType === 'team' && secondaryLeaderName || itemType === 'team' && !secondaryLeaderName && chosenFlag?.value === 2)*/}
            {(itemType === 'department' || itemType === 'team' && !secondaryLeaderName && chosenFlag?.value === 2)
                && <div className={style.input_text_wrapper}>
                    <label>Select {itemType === 'department' ? 'department leader'
                        : itemType === 'team' ? 'team leader' : ''}:</label>
                    <Select classNamePrefix="customSelect"
                            value={chosenLeader}
                            options={possibleLeaders}
                            isSearchable={false}
                            onChange={chooseLeader}/>
                </div>
            }

            {itemType === 'team' && secondaryLeaderName &&
                <>
                    <div className={style.choice_wrapper}>
                        <input {...register('choice')} id='choice1' type="radio" value="1"/>
                        <label htmlFor='choice1'>Remove previous leader from team</label>
                    </div>
                    <div className={style.choice_wrapper}>
                        <input  {...register('choice')} id='choice2' type="radio" value="2"/>
                        <label htmlFor='choice2'>Leave the previous leader as a team member</label>
                    </div>
                </>
            }

            {/*fixies required fields 31102023*/}
            {
                ((itemType === 'team' && !secondaryLeaderName && chosenFlag?.value === 1) || (itemType === 'team' && secondaryLeaderName)) &&
                <>
                    <div className={style.input_text_wrapper}>
                        <label htmlFor="email">Email</label>
                        <input {...register('email',
                            {
                                required: (chosenFlag && chosenFlag.value === 1) || (itemType === 'team' && secondaryLeaderName) || false,
                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                            })}
                               id="email"
                               placeholder='Enter email'/>
                    </div>
                    {errors.email?.type === "required" &&
                        <p className={style.error_message}>Email is required!</p>}
                    {errors.email?.type === "pattern" &&
                        <p className={style.error_message}>Invalid email address!</p>}


                    <div className={style.input_text_wrapper}>
                        <label htmlFor="firstName">First Name</label>
                        <input {...register('firstName', {
                            required: (chosenFlag && chosenFlag.value === 1) || (itemType === 'team' && secondaryLeaderName) || false,
                            pattern: /^[a-zA-Z][a-zA-Z-,]*[a-zA-Z]$/
                        })}
                               id="firstName"
                               placeholder='Enter first name'/>
                    </div>
                    {errors.firstName?.type === "required" && (
                        <p className={style.error_message}>First name is required!</p>
                    )}
                    {errors.firstName?.type === "pattern" && (
                        <p className={style.error_message}>Invalid first name format!</p>
                    )}


                    <div className={style.input_text_wrapper}>
                        <label htmlFor="lastName">Last Name</label>
                        <input {...register('lastName', {
                            required: (chosenFlag && chosenFlag.value === 1) || (itemType === 'team' && secondaryLeaderName) || false,
                            pattern: /^[a-zA-Z][a-zA-Z-,]*[a-zA-Z]$/
                        })}
                               id="lastName"
                               placeholder='Enter last name'/>
                    </div>
                    {errors.lastName?.type === "required" && (
                        <p className={style.error_message}>Last name is required!</p>
                    )}
                    {errors.lastName?.type === "pattern" && (
                        <p className={style.error_message}>Invalid last name format!</p>
                    )}

                </>
            }


            <div className={style.button_wrapper}>
                {!loading ? <ButtonWithImage height={46}
                                             name={'Save'}
                    // onClickHandler={saveChosenLeader}
                    //  onClickHandler={() => onSubmit()}
                                             form={'test'}
                                             type={'submit'}
                                             margin={'0 auto'}
                                             disabled={isDisabledButton}
                                             img={save}></ButtonWithImage> : <LoaderDots/>}
            </div>
        </form> : <div style={{margin: '30px auto'}}><LoaderDots/></div>
    )
}
export default ChooseModalWindowBody;