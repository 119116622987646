import React from "react";
import style from './membership.module.scss';
import InsertedImageRound from "../../insertedImageRound/insertedImageRound";
import {UserType} from "../switchAccount";
import {setAnchorElForAccountMenuAC, setCurrentStatusAC} from "../../../../redux/reducers/commonReducer";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {
    setChosenTeamIdAC, setChosenTeamInfoAC,
    setFilteredTeamsAC, setSelectedDepartmentsAC,
    setSelectedTeamMemberAC,
    setSelectedTeamsAC
} from "../../../../redux/reducers/dashboardReducer";
import {
    clearStateDigitalPulseBuildPageAC,
    setPreviewModeDigitalPulseAC
} from "../../../../redux/reducers/digitalPulseReducer";
import {RootStateType} from "../../../../redux/store/store";
import {setChosenTeamCoachingCornerAC} from "../../../../redux/reducers/coachingCornerReducer";
import {clearDepartmentManagementStateAC} from "../../../../redux/reducers/departmentManagementReducer";
import alarm from "../../../../assets/images/header/alarm_new.png";
import {Badge} from "@mui/material";

export type MembershipPropsType = {
    user: UserType
    key: number
}

const Membership = (props: MembershipPropsType) => {
    const dispatch = useDispatch();
    const currentStatus = useSelector((state: RootStateType) => state.common.currentStatus);
    let notificationsState = useSelector((state: RootStateType) => state.notificationsMenu);

    const changeRole = () => {
        //меняем роли - сетаем допустимые команды
        localStorage.setItem('role', props.user.role);
        dispatch(setCurrentStatusAC(props.user.role));
        dispatch(setAnchorElForAccountMenuAC(null));
        //очищаем выбранного мембера
        dispatch(setSelectedTeamMemberAC(null));
        dispatch(setSelectedTeamsAC([]));
        dispatch(setFilteredTeamsAC([]));
        dispatch(setSelectedDepartmentsAC([]));
        dispatch(setPreviewModeDigitalPulseAC(false));
        dispatch(setChosenTeamCoachingCornerAC(null));
        //для очистки стейта департаментов
        dispatch(clearDepartmentManagementStateAC())
        //для очистки выбранной айдишки на certainTeam
        dispatch(setChosenTeamIdAC(null));
        dispatch(setChosenTeamInfoAC(null));
        //очистка стейта DP_build
        dispatch(clearStateDigitalPulseBuildPageAC());
    }

    const unreadNotifications = props.user.role === 'isModerator' ? notificationsState.moderatorCount
        : props.user.role === 'isDepartmentAdmin' ? notificationsState.departmentLeaderCount
            : props.user.role === 'isAdmin' ? notificationsState.adminCount
                : props.user.role === 'isMember' ? notificationsState.memberCount : 0

    return (
        <>
            <NavLink
                to={(props.user.role === 'isModerator' || props.user.role === 'isDepartmentAdmin') ? '/dashboard' : '/dashboard/team'}
                onClick={changeRole}>
                <div className={style.membership}>
                    <div style={{position: 'relative'}}>
                        <Badge badgeContent={(props.user.role !== currentStatus && unreadNotifications) || 0} color="error">
                            <InsertedImageRound width={2.875} height={2.875} text={props.user.shortName}
                                                background={'#FFDA6E'}/>
                        </Badge>

                    </div>
                    <div className={style.membership_info}>
                        <div>{props.user.name}</div>
                        <div>{props.user.email}</div>
                        <div>{props.user.role === 'isAdmin' ? 'Team Leader' : props.user.role === 'isModerator' ? 'Administrator' : props.user.role === 'isDepartmentAdmin' ? 'Department Leader' : 'Member'}</div>
                    </div>
                </div>
                <div className={style.switch_account_separator}></div>
            </NavLink>
        </>
    )
}

export default Membership;
