import React from "react";
import style from './header.module.scss';
import InsertedImageRound from "../insertedImageRound/insertedImageRound";
import SwitchAccount from "../switchAccount/switchAccount";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store/store";
import {
    setAnchorElForAccountMenuAC, setAnchorElForNotificationsMenuAC, setShowSidebarCommonReducerAC,
} from "../../../redux/reducers/commonReducer";
import {useLocation} from "react-router-dom";
import menu from './../../../assets/images/common/menu.png';
import alarm from './../../../assets/images/header/alarm_new.png';
import SwitchNotifications from "../switchNotifications/switchNotifications";
import {useLaptopMini} from "../../../redux/useAdaptives";
import {Badge, Menu, Popover} from "@mui/material";

type HeaderPropsType = {
    pageName: string
}

const Header = React.memo((props: HeaderPropsType) => {
    const dispatch = useDispatch();
    const isLaptopMini = useLaptopMini();
    const state = useSelector((state: RootStateType) => state.common);
    const allNotifications = useSelector((state: RootStateType) => state.notificationsMenu.allNotifications);
    const unreadNotifications = allNotifications?.filter(n => !n.seen)?.length;
    const notificationsButtonColor = allNotifications?.some((n: any) => !n.seen) ? 'red' : 'white';
    const {anchorElForAccountMenu, anchorElForNotificationsMenu, tourCompleted} = state;
    const shortName = state.userInfo.shortName;
    const role = state.currentStatus;

    let location = useLocation();
    let url = location.pathname;

    const openSidebar = () => {
        dispatch(setShowSidebarCommonReducerAC(true))
    }

    const openAccountsMenu = (event: React.MouseEvent<HTMLElement>) => {
        dispatch(setAnchorElForAccountMenuAC(event.currentTarget))
    }

    const openNotificationsMenu = (event: React.MouseEvent<HTMLElement>) => {
        dispatch(setAnchorElForNotificationsMenuAC(event.currentTarget))
    }

    return (
        <div className={`${style.header} header`}
             style={{justifyContent: (url == 'questionnaire/checkins') ? '' : 'space-between'}}>

            {isLaptopMini &&
                <div className={'menu_button'} onClick={openSidebar}>
                    <img src={menu} alt=""/>
                </div>}
            <h1>{props.pageName}</h1>

            {url !== '/teamManagement/dashboard' &&
                <div className={`${style.header_buttons}`}>
                    <div
                        className={`${style.header_buttons_wrapper} ${(role === 'isModerator' && !tourCompleted) ? 'edit_profile_icon_for_moderator' : ''}
                ${(role === 'isDepartmentAdmin' && !tourCompleted) ? 'edit_profile_icon_for_DA' : ''}
                ${(role === 'isAdmin' && !tourCompleted) ? 'edit_profile_icon_for_leader' : ''}
                ${(role === 'isMember' && !tourCompleted) ? 'edit_profile_icon_for_member' : ''}`}>
                        <div id={'notificationsButton'} className='notifications_button'
                             onClick={openNotificationsMenu}>
                            <Badge badgeContent={unreadNotifications} color="error">
                                <InsertedImageRound width={2.8125} height={2.8125} text={shortName}
                                                    color={notificationsButtonColor}
                                                    image={alarm} active={true}/>
                            </Badge>

                        </div>
                        <div id={'accountsButton'} className='accounts_button' onClick={openAccountsMenu}>
                            <InsertedImageRound width={2.8125} height={2.8125} text={shortName} color={'yellow'}
                                                active={true}/></div>
                    </div>
                    <div
                        className={style.role}>{role === 'isAdmin' ? 'Team Leader' : role === 'isModerator' ? 'Administrator' : role === 'isDepartmentAdmin' ? 'Department Leader' : 'Member'}</div>

                </div>}

            {anchorElForAccountMenu && <Popover open={!tourCompleted ? true : Boolean(anchorElForAccountMenu)}
                                                anchorEl={anchorElForAccountMenu}
                                                onClose={() => dispatch(setAnchorElForAccountMenuAC(null))}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                PaperProps={{
                                                    style: {borderRadius: '10px'},
                                                }}
            >
                <SwitchAccount/>
            </Popover>}

            {anchorElForNotificationsMenu && <Popover open={Boolean(anchorElForNotificationsMenu)}
                                                      anchorEl={anchorElForNotificationsMenu}
                                                      onClose={() => dispatch(setAnchorElForNotificationsMenuAC(null))}
                                                      anchorOrigin={{
                                                          vertical: 'bottom',
                                                          horizontal: 'right',
                                                      }}
                                                      transformOrigin={{
                                                          vertical: 'top',
                                                          horizontal: 'right',
                                                      }}
            >
                <SwitchNotifications/>
            </Popover>}
        </div>
    )
})

export default Header;
