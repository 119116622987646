import React, {useEffect} from "react";
import style from './certainTeamPageInformation.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../../../redux/store/store";
import InsertedImage from "../../../../parts/insertedImage/insertedImage";
import pendingImage from './../../../../../assets/images/common/pending.png';
import {
    getCertainTeamInfoDepartmentManagementTC,
    setCertainTeamInfoDepartmentManagementAC, setLeaderTypeDepartmentManagementAC
} from "../../../../../redux/reducers/departmentManagementReducer";
import CertainTeamMember from "../certainTeamMember/certainTeamMember";
import addAdmin from "../../../../../assets/images/companyManagement/addAdmin.png";
import trash from './../../../../../assets/images/common/basket.png';
import addNewSecLeader from './../../../../../assets/images/companyManagement/addLeader_black.png';
import changeImage from './../../../../../assets/images/companyManagement/changeLeader.png';
import {setChosenItemForModalWindowCompanyManagementAC} from "../../../../../redux/reducers/companyManagementReducer";
import ButtonLinkInvite from "../../../companyManagement/companyInformation/buttonLinkInvite/buttonLinkInvite";
import {useHookShowInfoText} from "../../../../../redux/hookNavigate";
import {LoaderDots} from "@thumbtack/thumbprint-react";
import {useNavigate} from "react-router-dom";

type CertainTeamPageInformationPropsType = {
    onChangeLeaderModalWindowMode: (mode: boolean) => void,
    onChangeMemberModalWindowMode: (mode: boolean) => void,
    chooseAnItemForModalWindow: (action: 'add' | 'change' | 'choose' | 'delete' | null,
                                 item: 'admin' | 'department' | 'team' | 'member' | null) => void,
}

const CertainTeamPageInformation = (props: CertainTeamPageInformationPropsType) => {
    //для показа инфо-сообщения над кнопками
    const {infoMode, whoInfoMessage, showInfoMessage} = useHookShowInfoText();
    const dispatch = useDispatch();
    const state = useSelector((state: RootStateType) => state.departmentManagement);
    const team = state.certainTeamPage.certainTeamPageInfo;
    const secondaryLeaderName = state.certainTeamPage.certainTeamPageInfo.secondaryLeaderName;
    const navigate = useNavigate();

    const membersFromState = state.certainTeamPage.certainTeamPageInfo.members;
    let members = membersFromState?.map((m: any) => {
        if (!m.status) {
            return {...m, currentStatus: 'Invitation Sent'}
        } else if (m.status && !m.onboarding) {
            return {...m, currentStatus: 'Incomplete Onboarding'}
        } else {
            return {...m, currentStatus: 'Active'}
        }
    }) || []

    //для View more синенькой ссылки
    const [mode, changeMode] = React.useState<string>('more');
    const setNewMode = (mode: string) => {
        changeMode(mode)
    }

    const onChangeTeamLeader = () => {
        dispatch(setLeaderTypeDepartmentManagementAC('team'));
        props.onChangeLeaderModalWindowMode(true);
    }

    const addTeamMember = () => {
        dispatch(setLeaderTypeDepartmentManagementAC('member'));
        props.onChangeMemberModalWindowMode(true);
    }

    //functional for secondary team leader
    const addSecondaryLeader = () => {
        props.chooseAnItemForModalWindow('choose', 'team');
        dispatch(setChosenItemForModalWindowCompanyManagementAC(team));
    }

    const editSecondaryLeader = () => {
        props.chooseAnItemForModalWindow('choose', 'team');
        dispatch(setChosenItemForModalWindowCompanyManagementAC(team));
    }

    const deleteSecondaryLeader = () => {
        props.chooseAnItemForModalWindow('delete', 'team');
        dispatch(setChosenItemForModalWindowCompanyManagementAC(team));
    }

    //this is for buttons-invites (team leader link, department leader link, admin link)
    const btnLinks = [
        {link: team.link, btnName: 'Team Link', who: 'team dmctl' as const}
    ]

    useEffect(() => {
        //state.chosenTeam.id ---> это когда мы кликнули по команде конкретной и сохранили обьект chosenTeam
        //а санкой getCertainTeamInfoDepartmentManagementTC мы берем инфу по этой же команде но с дополнительными свойствами
        state.chosenTeam?.id ? dispatch(getCertainTeamInfoDepartmentManagementTC(+state.chosenTeam?.id)) : navigate('/dashboard')

        return (() => {
            let info = {
                id: 0,
                leaderName: '',
                secondaryLeaderName: null,
                link: '',
                members: [],
                name: '',
                surveysTimeSend: '',
            }
            dispatch(setCertainTeamInfoDepartmentManagementAC(info));
        })
    }, [state.chosenTeam?.id])

    return (
      team.id ? <div className={style.team_information}>
            <div className={`${style.team_information_leaders} border_1`}>
                <div className={style.team_information_leaders_info}>
                    <div className={style.team_information_leaders_info_item}>
                        <div>Team Name</div>
                        <div>{team.name}</div>
                    </div>
                    <div className={style.team_information_leaders_info_item}>
                        <div>Team Leader</div>
                        <div><span>{team.leaderName}</span></div>
                        <InsertedImage width={2} height={2} image={changeImage}
                                       handler={onChangeTeamLeader} cursor={'pointer'}/>
                    </div>
                    <div className={style.team_information_leaders_info_item}>
                        <div>Secondary Leader</div>
                        {secondaryLeaderName && secondaryLeaderName !== 'Pending' ? <div><span>{secondaryLeaderName}</span></div> :
                            secondaryLeaderName && secondaryLeaderName === 'Pending' ? <InsertedImage width={1.5} height={1.5} image={pendingImage}
                                                                                                      handler={() => {}} cursor={'auto'}/> : null}
                        {secondaryLeaderName && secondaryLeaderName !== 'Pending' ?
                            <>
                                <InsertedImage width={2} height={2} image={changeImage}
                                               handler={editSecondaryLeader} cursor={'pointer'}/>
                                <InsertedImage width={1.25} height={1.25} image={trash}
                                               handler={deleteSecondaryLeader} cursor={'pointer'}/>

                            </> : !secondaryLeaderName ? <InsertedImage width={1.25} height={1.25} image={addNewSecLeader}
                                                 handler={addSecondaryLeader} cursor={'pointer'}/> : null}
                    </div>
                </div>

                {btnLinks.map((btnLink, index) => <ButtonLinkInvite key={index}
                                                                     index={index}
                                                                     {...btnLink}
                                                                     infoMode={infoMode}
                                                                     whoInfoMessage={whoInfoMessage}
                                                                     showInfoMessage={showInfoMessage}/>)}
            </div>

            <div className={style.team_information_link}>
                <InsertedImage height={1.25} width={1.25} image={addAdmin} cursor={'pointer'}/>
                <div onClick={addTeamMember}>Add Team Member</div>
            </div>

            <div className={`${style.team_information_members} border_1`}>
                <div style={{minWidth: '650px'}}>
                    <h3>Team members</h3>
                    <div className='member_header'>
                        <div></div>
                        <div>First Name</div>
                        <div>Last Name</div>
                        <div>Email Address</div>
                        <div>Status</div>
                        <div style={{textAlign: 'center'}}>Action</div>
                        <div></div>
                    </div>
                    {
                        mode === 'more' ? members?.map((member, index) => {
                                    if (index < 4) {
                                        return <CertainTeamMember key={member.id}
                                                                  id={member.id}
                                                                  firstName={member.firstName}
                                                                  lastName={member.lastName}
                                                                  email={member.email} status={member.currentStatus}/>
                                    }
                                }
                            )
                            :
                            members?.map(member =>
                                <CertainTeamMember key={member.id}
                                                   id={member.id}
                                                   firstName={member.firstName}
                                                   lastName={member.lastName}
                                                   email={member.email} status={member.currentStatus}/>
                            )
                    }
                </div>
            </div>
            {
                mode === 'more' && members.length > 4 ?
                    <div className={style.team_information_link} onClick={() => setNewMode('less')}>&#62;&#62;View
                        More</div>
                    : mode === 'less' && members.length > 4 ?
                        <div className={style.team_information_link} onClick={() => setNewMode('more')}>&#62;&#62;View
                            Less</div> : <></>
            }
        </div> : <LoaderDots />
    )
}

export default CertainTeamPageInformation;