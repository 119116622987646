import React, {Children, ReactElement, ReactNode, useEffect, useState} from "react";
import style from './modalWindowWrapper.module.scss';
import cancel from "../../../assets/images/teamManagement/cancel.png";
import MessageBox from "../messageBox/messageBox";
import {useHookErrors} from "../../../redux/hookNavigate";


export type ModalWindowWrapperPropsType = {
    headerTitle: string,
    closeModalWindow: () => void,
    children: (props: {setError: (n: string) => void, setSuccess: (n: string) => void}) => ReactElement,
    isLarge?: boolean
}
//props: children, header, closeModalWindow
const ModalWindowWrapper: React.FC<ModalWindowWrapperPropsType> = ({headerTitle, closeModalWindow,
                                                                       children, isLarge}: ModalWindowWrapperPropsType) => {

    const {notification, isError, isSuccess, hideNotification, setError, setSuccess} = useHookErrors();

    useEffect(() => {
        document.body.classList.add('overflow-hidden')

        return (() => {
            document.body.classList.remove('overflow-hidden');
        })
    }, [])

    return(
        <div className={style.modal_wrapper}>
            <div className={`${style.modal_window} ${isLarge ? style.large : ''}`}>
                <div className={style.modal_window_header}>
                    <p>{headerTitle}</p>
                    <div className={style.cancel_button} onClick={closeModalWindow}>
                        <img src={cancel} alt=""/>
                    </div>
                </div>

                <div className={style.modal_window_separator}></div>

                <div className={style.modal_window_body}>

                    {
                        children({setError, setSuccess})
                    }

                    {(isError || isSuccess) && <div style={{position: 'fixed', top: 0, right: 0}}>
                        <MessageBox notification={notification} isError={isError} autoClosing={true}
                                    hideError={hideNotification} isSuccess={isSuccess}/>
                    </div>}

                </div>
            </div>
        </div>
    )
}

export default ModalWindowWrapper;