import React from "react";
import style from './switchAccount.module.scss';
import Membership from "./membership/membership";
import Section from "./section/section";
import profile from '../../../assets/images/switchAccount/profile.png';
import membership from '../../../assets/images/switchAccount/membership.png';
import password from '../../../assets/images/switchAccount/password.png';
import personality from '../../../assets/images/switchAccount/personality.png';
import signOut from '../../../assets/images/switchAccount/signOut.png';
import feedback from './../../../assets/images/feedback/feedback.png';
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store/store";
import {useNavigate} from "react-router-dom";
import {setAnchorElForAccountMenuAC} from "../../../redux/reducers/commonReducer";
import {ProfileActiveTabType, setActiveTabProfileAC} from "../../../redux/reducers/profileReducer";
import {setPreviewModeDigitalPulseAC} from "../../../redux/reducers/digitalPulseReducer";
import {logOutSignInTC} from "../../../redux/reducers/signInReducer";

export type UserType = {
    name: string,
    email: string,
    shortName: string,
    role: string
}

const SwitchAccount = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    //здесь из стэйта подтянуть всю инфу о пользователе
    //если больше одной роли то превращаем инфу в такой вид
    const user = useSelector((state: RootStateType) => state.common.userInfo);
    const {roles, currentStatus, anchorElForAccountMenu} = useSelector((state: RootStateType) => state.common);
    const userInfo = roles.map(role => ({...user, role: role.name}));

    const onClickSignOut = () => {
        let accounts = localStorage.getItem('visitedAccounts');
        localStorage.clear();
        accounts?.length && localStorage.setItem('visitedAccounts', accounts);
        dispatch(logOutSignInTC());
        // let email = localStorage.getItem('email');
        // localStorage.clear();
        // email && localStorage.setItem('email', email);
        // window.location.href = '/signIn'
    }

    const onClickSection = (section: ProfileActiveTabType) => {
        if (section === 'feedback') {
            navigate('/feedback');
        } else {
            navigate('/profile');
            dispatch(setActiveTabProfileAC(section));
        }
        anchorElForAccountMenu && dispatch(setAnchorElForAccountMenuAC(null));
        dispatch(setPreviewModeDigitalPulseAC(false));
    }

    return (
        <div className={`${style.switch_account_window} accounts_menu`}>
            <div className={style.switch_account_window_header}>Account</div>
            <div className={style.switch_account_separator}></div>
            {
                userInfo.map((user, index: number) => <Membership key={index} user={user}/>)
            }
            <Section image={profile} name={'Profile'} onClickHandler={() => onClickSection('edit')}/>
            {currentStatus === 'isModerator' &&
                <Section image={membership} name={'Membership'} onClickHandler={() => onClickSection('membership')}/>}
            <Section image={password} name={'Password & Security'} onClickHandler={() => onClickSection('security')}/>
            <Section image={personality} name={'Personality Assesment'}
                     onClickHandler={() => onClickSection('assessment')}/>
            <Section image={feedback} name={'Send Feedback'}
                     onClickHandler={() => onClickSection('feedback')}/>
            <div className={style.switch_account_separator}></div>
            <Section image={signOut} name={'Sign Out'} onClickHandler={onClickSignOut}/>
        </div>
    )
}

export default SwitchAccount;