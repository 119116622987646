import React, {useEffect} from "react";
import style from './messageBox.module.scss';

export type MessageBoxPropsType = {
    isError?: boolean,
    isSuccess?: boolean,
    hideError?: () => void,
    notification?: string | { text: string },
    isLongMessage?: boolean,
    autoClosing?: boolean,
    topPosition?: boolean,
    hideCloseButton?: boolean
}

const MessageBox = (props: MessageBoxPropsType) => {

    const handleCloseMessageBox = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.hideError && props.hideError();
    }

    useEffect(() => {
        if (props.autoClosing) {
            let timer = setTimeout(() => {
                props.hideError && props.hideError();
            }, 5000);

            return () => {
                clearTimeout(timer)
            }
        }
    }, [])

    return (
        <div className={style.error_container} onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
            <div className={`${style.error_content} ${props.isSuccess && style.success}`}
                 style={{display: props.isError || props.isSuccess ? 'block' : 'none'}}>
                <button type="button" className={style.close} style={{display: props.hideCloseButton ? 'none' : 'block'}} onClick={handleCloseMessageBox}>×</button>
                <div>
                  <span style={props.isLongMessage ? {display: 'inline-block', whiteSpace: 'pre-wrap'} : {}}>
                    {
                        props.notification
                        && typeof props.notification === 'object'
                            ? props.notification.text
                            : props.notification
                    }
                  </span>
                </div>
            </div>
        </div>
    )
}

export default MessageBox;
