import { useMediaQuery } from 'react-responsive'

export const useDesktop = (): boolean => useMediaQuery({ minWidth: 1440 })

export const useDesktopMini = (): boolean =>
    useMediaQuery({ maxWidth: 1439})

export const useLaptop = (): boolean =>
    useMediaQuery({ maxWidth: 1200})

export const useLaptopMini = (): boolean =>
    useMediaQuery({ maxWidth: 1024})

export const useMobile = (): boolean =>
    useMediaQuery({ maxWidth: 768})

export const useMobileMini = (): boolean =>
    useMediaQuery({ maxWidth: 576 })

export const useMobileExtraMini = (): boolean =>
    useMediaQuery({ maxWidth: 360 })
export const useLandscape = (): boolean =>
  useMediaQuery({ query: '(orientation: landscape)' })
