import React from "react";
import style from './selectWithImage.module.scss';
import InsertedImage from "../insertedImage/insertedImage";
import Select, {MultiValue, SingleValue} from 'react-select';

export type SelectWithImagePropsType = {
    name?: string,
    placeholder?: string,
    image: string,
    imageWidth: number,
    imageHeight: number,
    isMulti?: boolean,
    handler?: (selectedValues: any) => void,
    value?: Array<{ value: string, label: string }> | { value: string, label: string } | null,
    options?: Array<{ value: string, label: string }>
    loading?: boolean
}

const SelectWithImage = (props: SelectWithImagePropsType) => {
    const changeChosenValues = (v: MultiValue<{ value: string, label: string }> | SingleValue<{
        value: string,
        label: string
    }>) => {
        props.handler && props.handler(v);
    }


    return (
        <div className='data_select select_with_image_border'>
            <div className={'data_select_name'}>{props.name}</div>
            <div className={'data_select_main'}>
                <Select className={style.select} styles={{
                    // @ts-ignore
                    control: (provided, { isDisabled}) => ({
                        ...provided,
                        border: 'none',
                        outline: 'none',
                        boxShadow: '0 0 0 0 #FFF',
                        width: '100%',
                        backgroundColor: isDisabled ? 'white' : '',
                        opacity: isDisabled ? '.5' : '1'
                    }),
                    indicatorsContainer: (provided) => ({...provided, cursor: 'pointer'}),
                    indicatorSeparator: (provided) => ({...provided, display: 'none'}),
                    menu: (provided) => ({...provided, zIndex: '2'}),
                }}
                        value={props.value}
                        options={props.options}
                        isMulti={props.isMulti}
                        isDisabled={props.loading}
                        placeholder={props.placeholder}
                        onChange={changeChosenValues}/>
            </div>

            <div className={'data_select_image'}>
                <InsertedImage height={props.imageHeight} width={props.imageWidth} image={props.image}/>
            </div>
        </div>
    )
}

export default SelectWithImage;