import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {assessmentAPI} from "../../api/api";
import {addAlertMessageCommonReducerAC} from "./commonReducer";
import {errorAlertItem} from "../../common";

export type AssessmentQuestionType = {
    code: string,
    question: string,
    id: number,
    format: number,
    chosenValue: number
}

export type AssessmentInfoType = {
    allQuestions: Array<{ step: number, questions: Array<AssessmentQuestionType> }>,
    currentQuestions: Array<AssessmentQuestionType>,
    allSteps: number,
    allQuestionsCount: number,
    currentStep: number,
    description: string,
    personality: string,
    answeredQuestionsCount: number,
    unAnsweredQuestionsCount: number,
}

export type AssessmentStateType = {
    allQuestions: Array<{ step: number, questions: Array<AssessmentQuestionType> }>,
    currentQuestions: Array<AssessmentQuestionType>,
    allSteps: number,
    allQuestionsCount: number,
    currentStep: number,
    description: string,
    personality: string,
    answeredQuestionsCount: number,
    unAnsweredQuestionsCount: number,
}

const initialState: AssessmentStateType = {
    allQuestions: [],
    currentQuestions: [],
    allSteps: 0,
    allQuestionsCount: 0,
    currentStep: 0,
    description: '',
    personality: '',
    answeredQuestionsCount: 0,
    unAnsweredQuestionsCount: 0,
}

export const assessmentReducer = (state = initialState, action: AllActionType): AssessmentStateType => {
    switch (action.type) {
        case 'ASSESSMENT-SET-INFO': {
            return {...state, ...action.info}
        }
        case 'ASSESSMENT-ANSWER-THE-QUESTION': {
            let valueBeforeAnswer = state.currentQuestions.find(q => q.id === action.questionId)?.chosenValue;
            return {
                ...state,
                currentQuestions: state.currentQuestions.map(q => q.id === action.questionId ? {
                    ...q,
                    chosenValue: action.chosenValue
                } : q),
                unAnsweredQuestionsCount: !valueBeforeAnswer ? state.unAnsweredQuestionsCount - 1 : state.unAnsweredQuestionsCount,
                answeredQuestionsCount: !valueBeforeAnswer ? state.answeredQuestionsCount + 1 : state.answeredQuestionsCount,
            }
        }
        case 'ASSESSMENT-SET-CURRENT-QUESTIONS': {
            return {...state, currentQuestions: [...action.questions]}
        }
        case 'ASSESSMENT-SET-CURRENT-STEP': {
            return {...state, currentStep: action.step}
        }
        case 'ASSESSMENT-SET-ASSESSMENT-RESULTS': {
            return {...state, ...action.result}
        }
        default:
            return state
    }
}

export type setAssessmentInfoACType = ReturnType<typeof setAssessmentInfoAC>;
export type answerQuestionAssessmentACType = ReturnType<typeof answerQuestionAssessmentAC>;
export type setCurrentQuestionsAssessmentACType = ReturnType<typeof setCurrentQuestionsAssessmentAC>;
export type setCurrentStepAssessmentACType = ReturnType<typeof setCurrentStepAssessmentAC>;
export type setAssessmentResultsAssessmentACType = ReturnType<typeof setAssessmentResultsAssessmentAC>;

export const setAssessmentInfoAC = (info: AssessmentInfoType) => ({type: 'ASSESSMENT-SET-INFO', info} as const);
export const answerQuestionAssessmentAC = (questionId: number, chosenValue: number) => ({
    type: 'ASSESSMENT-ANSWER-THE-QUESTION',
    questionId,
    chosenValue
} as const);
export const setCurrentQuestionsAssessmentAC = (questions: Array<AssessmentQuestionType>) => ({
    type: 'ASSESSMENT-SET-CURRENT-QUESTIONS',
    questions
} as const);
export const setCurrentStepAssessmentAC = (step: number) => ({type: 'ASSESSMENT-SET-CURRENT-STEP', step} as const);
export const setAssessmentResultsAssessmentAC = (result: {
    description: string,
    personality: string
}) => ({type: 'ASSESSMENT-SET-ASSESSMENT-RESULTS', result} as const);


export const assessmentGetInfoTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {
    assessmentAPI.getQuestions().then(res => {

        let currentStep = res.data.currentStep;
        let allQuestionsCount = +res.data.countQuestionsSteps[0].questionCount;
        let allQuestions = res.data.assesmentQuestions.map((q: any) => ({
            step: q.step,
            questions: q.questions.map((i: any) => ({...i, chosenValue: 0}))
        }));
        let currentQuestions = allQuestions.find((q: any) => q.step === currentStep)?.questions || [];

        let answeredQuestionsCount = 0;


        for (let i = currentStep - 1; i > 0; i--) {
            answeredQuestionsCount += allQuestions.find((q: any) => q.step === i).questions.length;
        }

        let unAnsweredQuestionsCount = allQuestionsCount - answeredQuestionsCount;

        let assessmentInfo = {
            allQuestions: allQuestions,
            currentQuestions: currentQuestions,
            allSteps: res.data.countQuestionsSteps[0].steps,
            allQuestionsCount: allQuestionsCount,
            currentStep: currentStep,
            description: res.data.description,
            personality: res.data.personality,
            answeredQuestionsCount: answeredQuestionsCount,
            unAnsweredQuestionsCount: unAnsweredQuestionsCount,
        }
        dispatch(setAssessmentInfoAC(assessmentInfo));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}

export const assessmentSendStepAnswerTC = (answers: { [key: string]: number },
                                           currentStep: number,
                                           showFinishPage: (mode: boolean) => void,
                                           navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let allSteps = getState().assessment.allSteps;

    assessmentAPI.sendAnswers(answers, currentStep).then(res => {
            dispatch(setCurrentStepAssessmentAC(res.data.currentStep));

            if (currentStep === allSteps) {
                assessmentAPI.getPersonality().then(res => {
                    dispatch(setAssessmentResultsAssessmentAC(res.data));
                    showFinishPage(true);
                }).catch(err => {
                    dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
                })
            } else {
                window.scrollTo(0, 0);
            }
        }
    ).catch(error => {
        let status = error.response.data.status;
        if (status === 500 || status === 404) {
            navigate("/404");
        }
    })
}

export const retakeAssessmentTC = (navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    assessmentAPI.retakeAssessment().then(res => {
        navigate('/assessment')
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}