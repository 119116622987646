import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {checkInParticipationAPI} from "../../api/api";
import {addAlertMessageCommonReducerAC} from "./commonReducer";
import {errorAlertItem} from "../../common";

export type ReasonTypeServerResponse = {
    createdAt: string,
    id: number,
    title: string,
    updatedAt: string,
}

export type ReasonStateType = {
    id: number,
    value: string,
    checked: boolean
}

export type UnsubscribePageStateType = {
    reasons: Array<ReasonStateType> | null
}

const initialState: UnsubscribePageStateType = {
    reasons: null
}

export const unsubscribePageReducer = (state = initialState, action: AllActionType): UnsubscribePageStateType => {
    switch (action.type) {
        case 'SET-REASONS-UNSUBSCRIBE-PAGE': {
            return {...state, reasons: [...action.reasons]}
        }
        default:
            return state
    }
}

export type setReasonsUnsubscribePageACType = ReturnType<typeof setReasonsUnsubscribePageAC>;

export const setReasonsUnsubscribePageAC = (reasons: Array<ReasonStateType>) => ({
    type: 'SET-REASONS-UNSUBSCRIBE-PAGE',
    reasons
} as const);

export const getReasonsUnsubscribePageTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {
    checkInParticipationAPI.getReasons().then(res => {
        let reasons = res.data.reasons.map((reason: ReasonTypeServerResponse, index: number) => ({
            id: reason.id,
            value: reason.title,
            checked: index === 0
        }))
        dispatch(setReasonsUnsubscribePageAC(reasons))
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const sendReasonsUnsubscribePageTC = (userId: string,
                                             response: { reasonId: number, userId: string | undefined, other: string },
                                             changeMessageMode: (mode: boolean) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    checkInParticipationAPI.sendReason(+userId, response).then(res => {
        changeMessageMode(true);
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}