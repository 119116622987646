import React from "react";
import style from './certainTeamMember.module.scss';
import {StatusType} from "../../../teamManagment/teamInformation/member/member";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../../../redux/store/store";
import InsertedImageRound from "../../../../parts/insertedImageRound/insertedImageRound";
import user from "../../../../../assets/images/common/user.png";
import InsertedImage from "../../../../parts/insertedImage/insertedImage";
import basket from "../../../../../assets/images/common/basket.png";
import {
    nudgeTeamMemberDepartmentManagementTC,
    reinviteTeamMemberDepartmentManagementTC,
    removeTeamMemberDepartmentManagementTC
} from "../../../../../redux/reducers/departmentManagementReducer";
import {useHookErrors} from "../../../../../redux/hookNavigate";
import MessageBox from "../../../../parts/messageBox/messageBox";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";

type CertainTeamMemberPropsType = {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    status: StatusType,
}

const CertainTeamMember = (props: CertainTeamMemberPropsType) => {
    const dispatch = useDispatch();

    const {notification, isError, isSuccess, hideNotification, setError, setSuccess} = useHookErrors();

    const members = useSelector((state: RootStateType) => state.departmentManagement.certainTeamPage.certainTeamPageInfo.members);
    const memberStatus = members.find(member => member.id === props.id)?.status || false;
    const teamId = useSelector((state: RootStateType) => state.departmentManagement.certainTeamPage.certainTeamPageInfo.id);

    const statusColor = props.status === 'Active' ? '#19EAAA'
        :  props.status === 'Incomplete Onboarding' ? '#FFCF27'  : '#EE2828';

    const removeMember = () => {
        let deletion = confirm(`Are you sure you want to delete ${props.firstName} ${props.lastName}?`)
        deletion && dispatch(removeTeamMemberDepartmentManagementTC(memberStatus, teamId, props.email, props.id, setError))
    }

    const nudgeMember = () => {
        dispatch(nudgeTeamMemberDepartmentManagementTC(teamId, props.email,  setError, setSuccess))
    }

    const reinviteMember = () => {
        dispatch(reinviteTeamMemberDepartmentManagementTC(teamId, props.email,  setError, setSuccess))
    }

    return (
        <>
            <p style={{height: '1px', backgroundColor: '#D3D1D1', margin: '10px 0'}}></p>
            <div className={`member_header ${style.font}`} style={{position: 'relative'}}>
                {(isError || isSuccess) &&
                    <div style={{width: '100%', position: 'absolute', top: '0', left: '0', zIndex: '10'}}>
                        <MessageBox notification={notification} isError={isError} isSuccess={isSuccess}
                                    autoClosing={true} hideError={hideNotification}/>
                    </div>}
                <InsertedImageRound width={2.8125} height={2.8125} image={user}/>
                <div>{props.firstName}</div>
                <div>{props.lastName}</div>
                <div>{props.email}</div>
                <div>
                    <RadioGroup>
                        <FormControlLabel
                            value={props.status}
                            sx={{
                                '.MuiFormControlLabel-label': {
                                    fontFamily: 'Montserrat-Medium',
                                    fontSize: '1rem',
                                    lineHeight: '1.25rem',
                                    color: statusColor,
                                    marginLeft: '5px',
                                },
                                '.MuiButtonBase-root': {
                                    padding: '0'
                                }
                            }}
                            control={<Radio sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: 22,
                                    color: `${statusColor}`,
                                },
                            }} checked={true}/>}
                            label={props.status}
                        />
                    </RadioGroup>
                </div>
                <div>
                    {props.status === 'Incomplete Onboarding' &&
                        <button className={style.action_button} onClick={nudgeMember}>Nudge</button>}
                    {props.status === 'Invitation Sent' &&
                        <button className={style.action_button} onClick={reinviteMember}>Reinvite</button>}
                </div>
                <InsertedImage width={1.5625} height={1.5625} image={basket} handler={removeMember} cursor={'pointer'}/>
            </div>
        </>
    )
}

export default CertainTeamMember;