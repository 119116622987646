import React from "react";
import style from './insightItem.module.scss';
import InsertedImageRound from "../../../parts/insertedImageRound/insertedImageRound";
import like from '../../../../assets/images/coachingCorner/like.png';
import like_fill from '../../../../assets/images/coachingCorner/like_fill.png';
import InsertedImage from "../../../parts/insertedImage/insertedImage";
import {InsightItemType} from "../Insights/insights";
import logo from './../../../../assets/images/coachingCorner/logo.png';
import sparkle from './../../../../assets/images/coachingCorner/sparkle.png';

export type InsightItemPropsType = {
    insight: InsightItemType,
    like: () => void,
    dislike: () => void,
    onClickItem: () => void
}

const InsightItem = (props: InsightItemPropsType) => {

    return (
        <div className={style.insight_item}
             style={{opacity: props.insight.seen ? '.5' : '1', marginTop: props.insight.notificationType === 'ai_insight' ? '3rem' : ''}}>

            <InsertedImageRound height={4.1875} width={4.1875} image={props.insight.notificationType === 'ai_insight' ? sparkle : logo}/>

            <div className={`${style.insight_item_info} border`}>
                {props.insight.notificationType === 'ai_insight' &&
                    <span style={{position: 'absolute', top: '-2rem', left: 0, fontWeight: 'bold'}}>Generated via AI</span>}

                <div className={style.insight_item_info_text_block} onClick={props.onClickItem}>
                    <div>{props.insight.insightBody}</div>
                </div>

                <div className={style.insight_item_info_helpful_block}>Helpful?</div>

                <div className={style.insight_item_info_icons_block}>
                    <div className={style.insight_item_info_icons_block_time}>{props.insight.daysAgo}</div>

                    <div className={style.insight_item_info_icons_block_icons}>
                        <div className={style.insight_item_info_helpful_block_mobile}>Helpful?</div>
                        {props.insight.liked ?
                            <InsertedImage width={1.25} height={1.25} image={like_fill} cursor={'pointer'}
                                           handler={props.like}/> :
                            <InsertedImage width={1.25} height={1.25} image={like} cursor={'pointer'}
                                           handler={props.like}/>
                        }
                        {props.insight.disliked ?
                            <InsertedImage width={1.25} height={1.25} image={like_fill} cursor={'pointer'} rotate={true}
                                           handler={props.dislike} margin={'0 15px 0 0'}/> :
                            <InsertedImage width={1.25} height={1.25} image={like} cursor={'pointer'} rotate={true}
                                           handler={props.dislike} margin={'0 15px 0 0'}/>
                        }
                        <div className={'red_point'} style={{display: props.insight.seen ? 'none' : 'block'}}></div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InsightItem;