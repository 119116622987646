import React, {useEffect} from "react";
import {Outlet} from "react-router-dom";
import Sidebar from "../parts/sidebar/sidebar";
import FooterWithCopyRight from "../parts/footerWithCopyright/footerWithCopyright";
import {useLaptopMini} from "../../redux/useAdaptives";
import Drawer from '@mui/material/Drawer';
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../redux/store/store";
import {setShowSidebarCommonReducerAC} from "../../redux/reducers/commonReducer";
import {getNotificationsTC} from "../../redux/reducers/notificationsMenuReducer";

const MainLayout = () => {
    const dispatch = useDispatch();
    const {showSidebar, tourCompleted, currentStatus} = useSelector((state: RootStateType) => state.common);

    const toggleDrawer = (isOpen: boolean) => () => {
        dispatch(setShowSidebarCommonReducerAC(isOpen))
    };

    const isLaptopMini = useLaptopMini();

    //get notifications
    useEffect(() => {
        currentStatus.length && dispatch(getNotificationsTC(currentStatus))
    }, [currentStatus])

    useEffect(() => {

        return () => {
            showSidebar && dispatch(setShowSidebarCommonReducerAC(false))
        }
    }, [])

    return (
        <div className='common_wrapper'>
            {!isLaptopMini ?
                <div className="menu_block">
                    <Sidebar/>
                </div> :
                <Drawer open={!tourCompleted ? true : showSidebar}
                        onClose={toggleDrawer(false)}
                        style={{visibility: showSidebar ? 'visible' : 'hidden'}}>
                    <Sidebar/>
                </Drawer>
            }
            <div className='body_block add_position'>
                <Outlet/>
                <FooterWithCopyRight/>
            </div>
        </div>
    )
}

export default MainLayout;