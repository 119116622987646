import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {authAPI} from "../../api/api";
import {addAlertMessageCommonReducerAC, getRoles} from "./commonReducer";
import {errorAlertItem} from "../../common";

export type SignInStateType = {
    email: string,
    password: string,
    emailFromServer: string,
    isLoading: boolean,
    isError: boolean,
    isSuccess: false,
    notification: string,
    accountsFromServer: Array<{name: string, email: string, session: string}>
}

const initialState: SignInStateType = {
    email: '',
    password: '',
    emailFromServer: '',
    isLoading: false,
    isError: false,
    isSuccess: false,
    notification: '',
    accountsFromServer: []
}

export const signInReducer = (state = initialState, action: AllActionType) => {
    switch (action.type) {
        case 'SIGN-IN-SET-COMMON-QUERY-RESULT': {
            return {...state, ...action.result}
        }
        case 'SET-EMAIL-FROM-ANSWER-WITH-TOKEN': {
            return {...state, emailFromServer: action.email}
        }
        case 'SAVE-USER-EMAIL-SIGN-IN': {
            return {...state, email: action.email}
        }
        case 'GET-USER-ACCOUNTS-SIGN-IN': {
            return {...state, accountsFromServer: [...action.accounts]}
        }
        default:
            return state
    }
}

export type setCommonQueryResultSignInACType = ReturnType<typeof setCommonQueryResultSignInAC>;
export type setEmailFromAnswerWithTokenACType = ReturnType<typeof setEmailFromAnswerWithTokenAC>;
export type saveUserEmailSignInACType = ReturnType<typeof saveUserEmailSignInAC>;
export type getUserAccountsFromServerSignInACType = ReturnType<typeof getUserAccountsFromServerSignInAC>;

//обработка loading и ошибок
export const setCommonQueryResultSignInAC = (result: any) =>
    ({type: 'SIGN-IN-SET-COMMON-QUERY-RESULT', result} as const);

export const setEmailFromAnswerWithTokenAC = (email: string) => ({
    type: 'SET-EMAIL-FROM-ANSWER-WITH-TOKEN',
    email
} as const);

export const saveUserEmailSignInAC = (email: string) => ({type: 'SAVE-USER-EMAIL-SIGN-IN', email} as const);

//for new sign in
export const getUserAccountsFromServerSignInAC = (accounts: Array<{name: string, email: string, session: string}>) =>
    ({type: 'GET-USER-ACCOUNTS-SIGN-IN', accounts}as const);


////////////////по нажатию на кнопку login задействованы 3 следующие санки, в зависимости от наличия токена
export const authLoginSignInTC = (info: {
    email: string,
    password: string
}, tokenFromURL: string | undefined, socket: any, navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.authLogin(info).then(res => {
        dispatch(authLoginDataSignInTC({token: res.data.token}, tokenFromURL, socket, navigate))
    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({
            isLoading: false,
            isSuccess: false,
            isError: true,
            notification: err.response.data.message
        }));
    })
}

export const authLoginDataSignInTC = (info: {
    token: string
}, tokenFromURL: string | undefined, socket: any, navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {

    authAPI.authLoginData(info).then(res => {
        if (tokenFromURL) {
            dispatch(authLoginInviteJoinSignInTC(tokenFromURL, res.data.token, socket, navigate))
        } else {
            socket.emit('signIn', res.data.token);
            dispatch(setCommonQueryResultSignInAC({
                isLoading: false,
                isSuccess: false,
                isError: false,
                notification: ''
            }));
            localStorage.setItem('token', res.data.token);
            // window.location.href = '/dashboard';
            dispatch(getRoles(navigate))
            navigate('/dashboard');
        }

    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({
            isLoading: false,
            isSuccess: false,
            isError: true,
            notification: err.response.data.message
        }));
    })
}

export const authLoginInviteJoinSignInTC = (tokenFromURL: string, tokenFromResponse: string, socket: any, navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.authLoginInviteJoin(tokenFromURL).then(res => {
        //здесь добавила эмит мобытия при логировании токена (если был инвайт)
        socket.emit('signIn', tokenFromResponse);
        // console.log(socket.id)
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: false, notification: ''}));
        localStorage.setItem('token', tokenFromResponse);
        dispatch(getRoles(navigate));
        // window.location.href = '/dashboard';
        navigate('/dashboard');
    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({
            isLoading: false,
            isSuccess: false,
            isError: true,
            notification: err.response.data.message
        }));
    })
}
////////////////////////////


//проверка токена при загрузке страницы signIn
export const authLoginInviteCheckTC = (token: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.authLoginInviteCheck(token).then(res => {
        //это закомментила пока
        // let email = res.data.email;
        //
        // if (email.length) {
        //     localStorage.removeItem('email');
        //     localStorage.setItem('email', email);
        //     dispatch(setEmailFromAnswerWithTokenAC(email));
        // } else {
        //     let emailFromLS = localStorage.getItem('email');
        //     emailFromLS && dispatch(setEmailFromAnswerWithTokenAC(emailFromLS));
        // }

        dispatch(setCommonQueryResultSignInAC({
            isLoading: false,
            isSuccess: true,
            isError: false,
            notification: res.data.notification
        }));
    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({
            isLoading: false,
            isSuccess: false,
            isError: true,
            notification: err.response.data.message
        }));
    })
}

//санка для forgotPassword
export const authResetPasswordSignInTC = (info: {
    email: string
}, navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.authResetPassword(info).then(res => {
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: false, notification: ''}));
        navigate('/checkEmail')
    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({
            isLoading: false,
            isSuccess: false,
            isError: true,
            notification: err.response.data.message
        }));
    })
}

//санка для updatePassword
export const authValidateResetTokenSignInTC = (info: {
    token: string,
    password: string
}, navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.authValidateResetToken({token: info.token}).then(res => {
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: false, notification: ''}));
        authAPI.authUpdatePassword(info).then(res => {
            //window.location.href = '/dashboard'
            navigate('/dashboard')
        }).catch(err => {

            dispatch(setCommonQueryResultSignInAC({
                isLoading: false,
                isSuccess: false,
                isError: true,
                notification: err.response.data.message
            }));
        })
    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({
            isLoading: false,
            isSuccess: false,
            isError: true,
            notification: err.response.data.message
        }));
    })
}

//санка для reconfirm email
export const authReconfirmEmailSignInTC = (email: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.reconfirmEmail(email).then(res => {
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: false, notification: ''}));
    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({
            isLoading: false,
            isSuccess: false,
            isError: true,
            notification: err.response.data.message
        }));

    })
}


export const getUserAccountDataSignInTC = (email: string,
                                           allAccounts: Array<{name: string, email: string}>,
                                           setAllAccounts: (accs: Array<{name: string, email: string}>) => void,
                                           chooseAnAccount: (item: {name: string, email: string}, index: number) => void,
                                           setAddAccountMode: (mode: boolean) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.getUserAccountData(email).then(res => {
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: false, notification: ''}));

        let accountItem = res.data;

        if (allAccounts?.length) {
            // let accounts = JSON.parse(allAccountsFromLS);
            let existingAccount = allAccounts?.find((i: { name: string, email: string }) => i.email === email);
            if (!existingAccount) {
                //accounts.push(accountItem);
                localStorage.setItem('visitedAccounts', JSON.stringify([...allAccounts, accountItem]));
                setAllAccounts([...allAccounts, accountItem])
                chooseAnAccount(accountItem, allAccounts.length)
            } else {
                chooseAnAccount(accountItem, allAccounts.findIndex((el: {
                    name: string,
                    email: string
                }) => el.email === accountItem.email))
            }
        } else {
            localStorage.setItem('visitedAccounts', JSON.stringify([accountItem]));
            setAllAccounts([accountItem]);
            chooseAnAccount(accountItem, 0);
        }
        setAddAccountMode(false);

    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({
            isLoading: false,
            isSuccess: false,
            isError: true,
            notification: err.response.data.message
        }));

    })
}


export const getAllAccountsWithSessionSignInTC = (accounts: Array<{name: string, email: string}>): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.getAccountsWithSessions(accounts).then(res => {
        dispatch(getUserAccountsFromServerSignInAC(res.data.accounts));
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: false, notification: ''}));
    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({
            isLoading: false,
            isSuccess: false,
            isError: true,
            notification: err.response.data.message
        }));

    })
}

export const logOutSignInTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.logout().then(res => {
        window.location.href = '/signIn';
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}