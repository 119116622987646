import React, {useEffect} from "react";
import {AlertItemType, removeAlertMessageCommonReducerAC} from "../../../redux/reducers/commonReducer";
import {useDispatch} from "react-redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

//each alert is autocloseable after 5 seconds
const CustomAlert = ({id, message, status, showCloseButton}: AlertItemType) => {
    const dispatch = useDispatch();

    const deleteAlert = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatch(removeAlertMessageCommonReducerAC(id))
    }

    useEffect(() => {
        setTimeout(() => {
            dispatch(removeAlertMessageCommonReducerAC(id))
        }, 5000);
    }, [])

    return (
        <MuiAlert severity={status}
                  sx={{ backgroundColor: status === 'success' ? '#B7ED8BFF' : '#F67388FF',
                      fontFamily: 'Montserrat-Regular', marginBottom: '5px' }}
        action={showCloseButton ?
            <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={deleteAlert}
        >
            <CloseIcon fontSize="inherit" />
        </IconButton>: null}>{message}</MuiAlert>
)
}

export default CustomAlert;