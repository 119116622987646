import React, {useEffect} from "react";
import Header from "../../parts/header/header";
import Tab from "../../parts/tab/tab";
import Insights from "./Insights/insights";
import Trends from "./Trends/trends";
import MotivationChatBox from "./motivationChatBox/motivationChatBox";
import {
    activeTabCoachingCornerAC,
    CoachingCornerTeamType,
    getTeamsCoachingCornerTC,
    setChosenTeamCoachingCornerAC,
    setPostFromMotivationChatBoxCoachingCornerAC
} from "../../../redux/reducers/coachingCornerReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store/store";
import {useNavigate} from "react-router-dom";
import CoachingCornerTeamSelect from "./teamSelect/coachingCornerTeamSelect";

export type ActiveTabCoachingCorner = 'insights' | 'trends' | 'motivation';

const CoachingCorner = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const role = useSelector((s: RootStateType) => s.common.currentStatus);
    const state = useSelector((state: RootStateType) => state.coachingCorner);
    const chosenTeamId = state.chosenTeamId;
    const teamName = state.availableTeams.find((team: CoachingCornerTeamType) => team.id === chosenTeamId)?.name;
    const activeTab = state.activeTab;

    const onChangeActiveTab = (tab: ActiveTabCoachingCorner) => {
        dispatch(activeTabCoachingCornerAC(tab))
    }

    const onSelectTeam = (teamId: number) => {
        dispatch(setChosenTeamCoachingCornerAC(teamId));
    }

    const gotToChatBox = () => {
        dispatch(setPostFromMotivationChatBoxCoachingCornerAC(false));
        dispatch(activeTabCoachingCornerAC('motivation'));
    }

    useEffect(() => {
        if (role !== 'isAdmin' && role !== 'isMember') {
            navigate('/dashboard');
        }
    }, [role])

    useEffect(() => {
        role.length && dispatch(getTeamsCoachingCornerTC())
    }, [])

    return (
        <>
            <Header pageName={'Coaching Corner'}/>

            <div className='team_selection'>
                <CoachingCornerTeamSelect changeTeam={(teamId) => onSelectTeam(teamId)} teamName={teamName || ''}
                                          availableTeams={state.availableTeams} activeTab={activeTab}/>
            </div>

            <div className={'tabs_block'}>
                <Tab tabName={'Insights from unboXt HQ'} activeTab={activeTab === 'insights'}
                     handler={() => onChangeActiveTab('insights')}/>

                <Tab tabName={'Trends'} activeTab={activeTab === 'trends'}
                     handler={() => onChangeActiveTab('trends')}/>
                <Tab tabName={'Motivation Chat Box'} activeTab={activeTab === 'motivation'} handler={gotToChatBox}/>
            </div>

            <div className={'common_pages_block add_padding'}>
                {activeTab === 'insights' && <Insights/>}
                {activeTab === 'trends' &&
                    <Trends changeActiveTab={(tab: ActiveTabCoachingCorner) => onChangeActiveTab(tab)}/>}
                {activeTab === 'motivation' && <MotivationChatBox/>}
            </div>
        </>
    )
}

export default CoachingCorner;