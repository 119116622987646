import React, {useCallback, useEffect, useState} from "react";
import ChangeLeaderDepartmentManagementModalWindow
    from "../../departmentManagement/modalWindows/changeLeaderDepartmentManagementModalWindow/changeLeaderDepartmentManagementModalWindow";
import InviteMemberLeaderModalWindow
    from "../../departmentManagement/modalWindows/inviteMemberLeaderModalWindow/inviteMemberLeaderModalWindow";
import Header from "../../../parts/header/header";
import Tab from "../../../parts/tab/tab";
import DepartmentInformation from "../../departmentManagement/departmentInformation/departmentInformation";
import {LoaderDots} from "@thumbtack/thumbprint-react";
import {
    addNewSecondaryTeamLeaderTC,
    changeSecondaryTeamLeaderTC, chooseNewSecondaryTeamLeaderTC, deleteDepartmentTC,
    getDepartmentInfoDepartmentManagementTC,
} from "../../../../redux/reducers/departmentManagementReducer";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {RootStateType} from "../../../../redux/store/store";
import {setChosenItemForModalWindowCompanyManagementAC} from "../../../../redux/reducers/companyManagementReducer";
import ModalWindowWrapper from "../../../parts/modalWindowWrapper/modalWindowWrapper";
import ChooseModalWindowBody from "../companyInformation/chooseModalWindowBody/chooseModalWindowBody";
import DeleteModalWindowBody from "../../departmentManagement/modalWindows/deleteModalWindowBody/deleteModalWindowBody";
import {useHookErrors} from "../../../../redux/hookNavigate";
import MessageBox from "../../../parts/messageBox/messageBox";
import {addAlertMessageCommonReducerAC} from "../../../../redux/reducers/commonReducer";
import {errorAlertItem} from "../../../../common";

const DepartmentView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {currentStatus} = useSelector((state: RootStateType) => state.common);
    const {
        loading,
        chosenDepartment,
        departmentInfo,
        certainTeamPage
    } = useSelector((state: RootStateType) => state.departmentManagement);
    let [activeTab, changeActiveTab] = React.useState('information');
    const {notification, isError, isSuccess, hideNotification, setError, setSuccess} = useHookErrors();

    const {chosenItemForModalWindow, possibleLeaders} = useSelector((state: RootStateType) => state.companyManagement);
    let secondaryLeaderName = certainTeamPage.certainTeamPageInfo.secondaryLeaderName;

    //открыть модалку по изменению лидера департамента или команды
    let [leaderModalWindowMode, setLeaderModalWindowMode] = useState<boolean>(false);
    const onChangeLeaderModalWindowMode = (mode: boolean) => {
        setLeaderModalWindowMode(mode)
    }

    //открыть модалку по добавлению мембера команды или приглашению лидера
    let [memberModalWindowMode, setMemberModalWindowMode] = useState<boolean>(false);
    const onChangeMemberModalWindowMode = (mode: boolean) => {
        setMemberModalWindowMode(mode)
    }

    //для всех модалок
    const [modalView, setModalView] = useState<boolean>(false);
    const [actionType, setActionType] = useState<'add' | 'change' | 'choose' | 'delete' | null>(null);
    const [itemType, setItemType] = useState<'admin' | 'department' | 'team' | 'member' | null>(null);
    const closeModalWindow = () => {
        setModalView(false);
        setActionType(null);
        setItemType(null);
        dispatch(setChosenItemForModalWindowCompanyManagementAC(null));
    }

    //при нажатии на change или add new должна будет везде вызываться эта функция
    const chooseAnItemForModalWindow = (action: 'add' | 'change' | 'choose' | 'delete' | null,
                                        item: 'admin' | 'department' | 'team' | 'member' | null) => {
        setModalView(true);
        action && setActionType(action);
        item && setItemType(item);
    }

    //это функция по нажатиюю кнопки в самой модалке
    let actionForChoosingLeader = useCallback((chosenItemForModalWindowId: number | string,
                                               setError: (notification: string) => void,
                                               setSuccess: (notification: string) => void,
                                               chosenLeaderId: number | string | null,
                                               newInvitedUserInfo: {
                                                   email: string,
                                                   firstName: string,
                                                   lastName: string
                                               }, infoKeep: { keep: boolean } | null) => {

        //сюда еще санку на change secondary team leader
        //fixies 31102023
        itemType === 'team' && !chosenLeaderId && secondaryLeaderName && infoKeep ? dispatch(changeSecondaryTeamLeaderTC(chosenItemForModalWindowId,
                chosenLeaderId, newInvitedUserInfo, infoKeep, setError, setSuccess, closeModalWindow))
            : itemType === 'team' && !chosenLeaderId && !secondaryLeaderName ? dispatch(addNewSecondaryTeamLeaderTC(chosenItemForModalWindowId,
                    chosenLeaderId, newInvitedUserInfo, setError, setSuccess, closeModalWindow, null, 'departmentManagement'))
                // : itemType === 'team' && chosenLeaderId && secondaryLeaderName && infoKeep ? dispatch(changeSecondaryTeamLeaderTC(chosenItemForModalWindowId,
                //         chosenLeaderId, infoKeep, setError, setSuccess, closeModalWindow, 'departmentManagement'))
                : itemType === 'team' && chosenLeaderId && !secondaryLeaderName ? dispatch(chooseNewSecondaryTeamLeaderTC(chosenItemForModalWindowId,
                        chosenLeaderId, setError, setSuccess, closeModalWindow, 'departmentManagement', 'departmentManagement'))
                    : () => {
                        dispatch(addAlertMessageCommonReducerAC({
                            ...errorAlertItem,
                            message: 'something wrong choosing leader!'
                        }))
                    };
    }, [itemType, secondaryLeaderName]);

    const modalWindowTitle = itemType === 'team' && actionType === 'choose' ? 'Choose Secondary Team Leader'
        : itemType === 'department' && actionType === 'delete' ? 'Delete Department' : '';

    let chosenItemId = itemType === 'team' && chosenItemForModalWindow && ('id' in chosenItemForModalWindow) ? chosenItemForModalWindow.id : '';

    const deleteDepartment = () => {
        dispatch(deleteDepartmentTC(departmentInfo.id, navigate, setError))
    }

    useEffect(() => {
        if (currentStatus !== 'isModerator') {
            navigate('/dashboard', {replace: true});
        }
    }, [currentStatus])

    useEffect(() => {
        if (chosenDepartment) {
            dispatch(getDepartmentInfoDepartmentManagementTC(+chosenDepartment.id));
        }
    }, [chosenDepartment])

    return (
        <>
            {(isError || isSuccess) &&
                <div style={{width: '50%', position: 'fixed', top: 80, right: 0, zIndex: 114}}>
                    <MessageBox hideError={hideNotification}
                                isSuccess={isSuccess}
                                isError={isError}
                                notification={notification}
                                autoClosing={true}/></div>
            }

            {leaderModalWindowMode && <ChangeLeaderDepartmentManagementModalWindow
                onChangeLeaderModalWindowMode={onChangeLeaderModalWindowMode}/>}
            {memberModalWindowMode &&
                <InviteMemberLeaderModalWindow onChangeMemberModalWindowMode={onChangeMemberModalWindowMode}/>}

            {modalView && actionType ?
                <ModalWindowWrapper closeModalWindow={closeModalWindow} headerTitle={modalWindowTitle}>
                    {
                        actionType === 'delete' && itemType === 'department' ? (injectedProps) => (
                                <DeleteModalWindowBody itemToDelete={departmentInfo.name}
                                                       closeModalWindow={closeModalWindow}
                                                       hideNotification={hideNotification}
                                                       actionHandler={deleteDepartment}/>)
                            : actionType === 'choose' ? (injectedProps) => (
                                    <ChooseModalWindowBody itemType={itemType}
                                                           actionType={actionType}
                                                           possibleLeaders={possibleLeaders}
                                                           chosenItemForModalWindowId={chosenItemId}
                                                           chooseNewLeader={actionForChoosingLeader}
                                                           closeModalWindow={closeModalWindow}
                                                           secondaryLeaderName={secondaryLeaderName}
                                                           {...injectedProps}/>)
                                : (injectedProps) => <></>
                    }
                </ModalWindowWrapper> : null
            }

            <Header pageName={`Company Management - ${departmentInfo.name}`}/>

            <div className={'tabs_block'}>
                <Tab tabName={'Department Information'} activeTab={activeTab === 'information'}
                     handler={() => changeActiveTab('information')}/>
            </div>

            <div className={'common_pages_block add_padding'}>
                {!loading && activeTab === 'information' && chosenDepartment ?
                    <DepartmentInformation onChangeLeaderModalWindowMode={onChangeLeaderModalWindowMode}
                                           departmentInfo={departmentInfo}
                                           onChangeMemberModalWindowMode={onChangeMemberModalWindowMode}
                                           chooseAnItemForModalWindow={chooseAnItemForModalWindow}
                    /> : <LoaderDots/>
                }
            </div>

        </>
    )
}

export default DepartmentView;