import React from "react";
import style from './modalWindowCoachingCorner.module.scss';
import cancel from './../../../../assets/images/coachingCorner/cancel.png';
import {useSelector} from "react-redux";
import {RootStateType} from "../../../../redux/store/store";
import logo from '../../../../assets/images/coachingCorner/logo.png';
import {ActiveTabCoachingCorner} from "../coachingCorner";
import sparkle from './../../../../assets/images/coachingCorner/sparkle.png';

export type ModalWindowCoachingCornerPropsType = {
    closeModalWindow: () => void,
    changeActiveTab?: (tab: ActiveTabCoachingCorner) => void
}

const ModalWindowCoachingCorner = (props: ModalWindowCoachingCornerPropsType) => {
    let state = useSelector((state: RootStateType) => state.coachingCorner);
    const goToMotivation = () => {
       props.changeActiveTab && props.changeActiveTab('motivation')
    }

    return(
        <div className={style.modal_window}>

            <div className={style.modal_window_info_block}>
                <div className={style.modal_window_header}>
                    <div className={style.modal_window_header_logo}>
                        <img src={state.itemInModalWindow?.notificationType === 'ai_insight' ? sparkle : logo} alt=""/>
                    </div>
                    <div>{state.itemInModalWindow?.modalHeader}</div>

                    <div className={style.cancel_button} onClick={props.closeModalWindow}>
                        <img src={cancel} alt=""/>
                    </div>
                </div>

                <div className={style.modal_window_info_block_text}>{state.itemInModalWindow?.insightBody}</div>

                {state.itemInModalWindow?.modalHeader === "Motivation Trend" &&  <div className={style.modal_window_link}>
                    <span onClick={goToMotivation}>See more</span>
                </div>}
            </div>

        </div>
    )
}

export default ModalWindowCoachingCorner;