import React, {useState} from "react";
import style from './departmentInformation.module.scss';
import EditableSpan from "../../../parts/editableSpan/editableSpan";
import InsertedImage from "../../../parts/insertedImage/insertedImage";
import {useDispatch} from "react-redux";
import Team from "./team/team";
import {
    changeDepartmentNameTC, DepartmentInfoType, setChosenDepartmentDepartmentManagementAC,
    setLeaderTypeDepartmentManagementAC
} from "../../../../redux/reducers/departmentManagementReducer";
import MessageBox from "../../../parts/messageBox/messageBox";
import addAdmin from "../../../../assets/images/companyManagement/addAdmin.png";
import changeImage from './../../../../assets/images/companyManagement/changeLeader.png';
import {useNavigate} from "react-router-dom";
import ButtonLinkInvite from "../../companyManagement/companyInformation/buttonLinkInvite/buttonLinkInvite";
import {useHookShowInfoText} from "../../../../redux/hookNavigate";

export type DepartmentInformationPropsType = {
    departmentInfo: DepartmentInfoType,
    onChangeLeaderModalWindowMode: (mode: boolean) => void,
    onChangeMemberModalWindowMode: (mode: boolean) => void,
    chooseAnItemForModalWindow?: (action: 'add' | 'change' | 'choose' | 'delete' | null,
                                  item: 'admin' | 'department' | 'team' | 'member' | null) => void,
}

const DepartmentInformation = (props: DepartmentInformationPropsType) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState<boolean>(false);
    const [notification, setNotification] = useState<string>('');

    //для показа инфо-сообщения над кнопками
    const {infoMode, whoInfoMessage, showInfoMessage} = useHookShowInfoText();

    const hideError = () => {
        setError(false);
        setNotification('');
    }

    //для изменения наименования департамента
    const changeDepartmentName = (newName: string) => {
        dispatch(changeDepartmentNameTC(props.departmentInfo.id, newName,
            (error: boolean) => setError(error),
            (notification: string) => setNotification(notification), navigate));
    }

    //для View more синенькой ссылки
    const [mode, changeMode] = React.useState<string>('more');
    const setNewMode = (mode: string) => {
        changeMode(mode)
    }

    let teamsFromState = props.departmentInfo.teams;
    let teams = teamsFromState?.map((t: any) => {
        if (!t.status) {
            return {...t, currentStatus: 'Invitation Sent'}
        } else if (t.status && !t.onboardingCompleted) {
            return {...t, currentStatus: 'Incomplete Onboarding'}
        } else {
            return {...t, currentStatus: 'Active'}
        }
    }) || [];

    const onChangeDepartmentLeader = () => {
        dispatch(setLeaderTypeDepartmentManagementAC('department'));
        props.onChangeLeaderModalWindowMode(true);
    }

    const inviteTeamLeader = () => {
        dispatch(setLeaderTypeDepartmentManagementAC('team'));
        props.onChangeMemberModalWindowMode(true);
    }

    const deleteDepartment = () => {
        props.chooseAnItemForModalWindow && props.chooseAnItemForModalWindow('delete', 'department');
    }

    //this is for buttons-invites (team leader link, department leader link, admin link)
    const btnLinks = [
        {link: '', btnName: 'Delete Department', who: 'delete dm' as const, onSubmitAction: deleteDepartment},
        {link: props.departmentInfo.link, btnName: 'Department Link', who: 'department dm' as const}
    ]

    return (
        <div className={style.department_information}>
            {error &&
                <MessageBox autoClosing={true} isError={error} hideError={hideError} notification={notification}/>}

            <div className={`${style.department_information_leaders} border_1`}>
                <div className={style.department_information_leaders_info}>
                    <div className={style.department_information_leaders_info_item}>
                        <div>Department Name</div>
                        <EditableSpan title={props.departmentInfo.name}
                                      onChangeHandler={(newName) => changeDepartmentName(newName)}/>
                    </div>
                    <div className={style.department_information_leaders_info_item}>
                        <div>Department Leader</div>
                        <div><span>{props.departmentInfo.leader}</span></div>
                        <InsertedImage width={2} height={2} image={changeImage}
                                       handler={onChangeDepartmentLeader} cursor={'pointer'}/>
                    </div>
                    <div className={style.department_information_leaders_info_item}>
                        <div>No. of Teams</div>
                        <div><span>{props.departmentInfo.teamscount}</span></div>
                    </div>
                </div>

                <div className={style.department_information_leaders_buttons}>
                    {btnLinks.map((btnLink, index) => <ButtonLinkInvite key={index}
                                                                        index={index}
                                                                        {...btnLink}
                                                                        infoMode={infoMode}
                                                                        whoInfoMessage={whoInfoMessage}
                                                                        showInfoMessage={showInfoMessage}/>)}
                </div>
            </div>

            <div className={style.department_information_link}>
                <InsertedImage height={1.25} width={1.25} image={addAdmin} cursor={'pointer'}/>
                <div onClick={inviteTeamLeader}>Invite Team Leaders</div>
            </div>

            <div className={`${style.department_information_members} border_1`}>
                <div style={{minWidth: '650px'}}>
                    <h3>Teams</h3>
                    <div className='team_header'>
                        <div></div>
                        <div>Team</div>
                        <div>No. of Members</div>
                        <div>Team Leader</div>
                        <div>Secondary Leader</div>
                        <div>Email Address</div>
                        <div>Status</div>
                        <div style={{textAlign: 'center'}}>Action</div>
                        <div></div>
                    </div>
                    {
                        mode === 'more' ? teams?.map((team: any, index: number) => {
                                    if (index < 4) {
                                        return <Team key={team.id} id={team.id} teamName={team.name}
                                                     membersCount={team.membersCount}
                                                     leader={team.leader}
                                                     secondaryLeaderName={team.secondaryLeaderName}
                                                     email={team.email} status={team.currentStatus}
                                                     chooseAnItemForModalWindow={props.chooseAnItemForModalWindow}/>
                                    }
                                }
                            )
                            :
                            teams?.map((team: any) =>
                                <Team key={team.id} id={team.id} teamName={team.name}
                                      membersCount={team.membersCount}
                                      leader={team.leader}
                                      secondaryLeaderName={team.secondaryLeaderName}
                                      email={team.email} status={team.currentStatus}
                                      chooseAnItemForModalWindow={props.chooseAnItemForModalWindow}/>
                            )
                    }
                </div>
            </div>
            {
                mode === 'more' && teams.length > 4 ?
                    <div className={style.department_information_link} onClick={() => setNewMode('less')}>&#62;&#62;View
                        More</div>
                    : mode === 'less' && teams.length > 4 ?
                        <div className={style.department_information_link} onClick={() => setNewMode('more')}>&#62;&#62;View
                            Less</div>
                        : <></>
            }
        </div>
    )
}

export default DepartmentInformation;